// import SignupForm from './SignupForm'

const Home = () => {
  return <div>

    <header>
      <h1>Big headline news</h1>
      <p>News alerts for big stories only—no junk</p>
    </header>

    <div className="newspaper">

      <p className="headline">News is killing our culture</p>

      <p className="paragraph">
        The toxic 24-hour news cycle is rotting our brains and our culture.
        We're simultaneously uninformed and overexposed.
        The constant barrage of trivial stories, the out-of-context hand-wringing, the one-liner bite-backs, the ideological moralists, and the placated cynics have taken over.
        We've retreated to our comfortable corners and turned our back on public discourse.
        {/* We all know it, but we feel helpless to change it. */}
      </p>
      <p className="paragraph">
        If only there was a better way...
      </p>

    </div>
      
    <main>
      <h2>A healthier way to stay informed</h2>
      <p>
        The answer to toxic news is not getting rid of the news.
      </p>
      <p>
        The answer is less news. And the right news.
        The news that keeps us informed, but saves our energy for more important things in life.
      </p>

      <p>
        <strong>Big Headline News is a news service that delivers only one thing: important breaking stories.</strong>
        {' '}
        If there's nothing big to report, you won't be notified.
      </p>

      <p>
        So put down the the apps and turn off the TV.
        Rest assured: when something big happens, you'll be the first to know.
      </p>

      {/* <ul>
        <li>Stay informed</li>
        <li>Get alerted early</li>
      </ul>

      <ul>
        <li>No doom scrolling</li>
        <li>No daily emails</li>
        <li>No notifications</li>
        <li>No constantly checking feeds</li>
      </ul> */}

      <h2>FAQs</h2>
      <p>
        <strong>What counts as important news?</strong>
      </p>
      <p>
        We use the size of the headline on the New York Times homepage as a gauge for importance
      </p>
      <p>
        <strong>How much does it cost?</strong>
      </p>
      <p>
        This service is free. Someday, we may ask for donations or launch a premium tier to pay for the server costs.
      </p>

      {/* <p>
        [Neil postman quote goes here]
      </p> */}

      <h2>Unplug without losing touch</h2>
      <p>
        Start getting alerts
      </p>
      <p>
        Access coming soon
      </p>
      {/* <SignupForm /> */}

    </main>

  </div>
}

export default Home
